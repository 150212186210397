<template>
  <Form :heading1="'Create profile'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Name'"
            @input="setNames"
            :types="'text'"
            :defaultValue="appointee ? appointee.names : ''"
          />
          <TextInput
            :label="'Email'"
            @input="setEmail"
            :types="'text'"
            :defaultValue="appointee ? appointee.email : ''"
          />
          <TextInput
            :label="'Address'"
            @input="setAddress"
            :types="'text'"
            :defaultValue="appointee ? appointee.address : ''"
          />
          <TextInput
            :label="'Title / Institution'"
            @input="setTitle"
            :types="'text'"
            :not="true"
            :defaultValue="appointee ? appointee.title : ''"
          />
        </div>

        <div class="flex-1">
          <TextInput
            :label="'Telephone number'"
            @input="setMobile"
            :types="'text'"
            :defaultValue="appointee ? appointee.mobile : ''"
          />
          <TextInput
            :label="'Year of Birth'"
            @input="setAge"
            :types="'number'"
            :not="true"
            :defaultValue="appointee ? appointee.age : ''"
          />

          <TextInput
            :label="'Position'"
            @input="setPosition"
            :types="'text'"
            :defaultValue="appointee ? appointee.position : ''"
          />
          <TextInput
            :label="'New Position'"
            @input="setNewPosition"
            :types="'text'"
            :not="true"
            :defaultValue="appointee ? appointee.newPosition : ''"
          />
        </div>
      </div>

      <Button
        :first-l="'Prev'"
        :backUrl="'/create-appointment/appointmentdetails?cached=true'"
        :last-l="'Next'"
        @action="doCreate"
      />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "CreateSecondStep",
  components: {
    Button,
    TextInput,
    Form,
  },
  computed: {
    ...mapState("appointment", { appointee: "appointee" }),
  },
  beforeMount() {
    if (!this.$route.query.cached) {
      this.createAppointee({
        names: "",
        email: "",
        address: "",
        title: "",
        mobile: "",
        nationalId: "",
        description: "",
        age: "",
        newPosition: "",
        position: "",
        clear: true,
      });
    }
  },
  methods: {
    setNames(value) {
      this.appointee.names = value;
    },
    setEmail(value) {
      this.appointee.email = value;
    },
    setAddress(value) {
      this.appointee.address = value;
    },
    setTitle(value) {
      this.appointee.title = value;
    },
    setMobile(value) {
      this.appointee.mobile = value;
    },
    setAge(value) {
      this.appointee.age = value;
    },
    setNewPosition(value) {
      this.appointee.newPosition = value;
    },
    setPosition(value) {
      this.appointee.position = value;
    },
    ...mapMutations("appointment", { createAppointee: "setAppointee" }),
    doCreate() {
      const { names, email, mobile,address } = this.appointee;
      if (names == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Names are required",
          uptime: 1000,
          hasError: true,
        });
      } else if (email == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "email is required",
          uptime: 1000,
          hasError: true,
        });
      } else if (address == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "address is required",
          uptime: 1000,
          hasError: true,
        });
      }else if (mobile == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "phone number is required",
          uptime: 1000,
          hasError: true,
        });
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(email).toLowerCase()
        )
      ) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Email does not exit",
          uptime: 3000,
          hasError: true,
        });
      } else if (/^\+?\d+$/.test(mobile) == false) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Invalid telephone number",
          uptime: 3000,
          hasError: true,
        });
      } else if (mobile.length != 12) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "telephone number must be 12 digits",
          uptime: 3000,
          hasError: true,
        });
      } else {
        // this.$store.dispatch("notification/SET_NOTIFICATION", {
        //   msg: "Adding appointee ...",
        //   uptime: 3000,
        // });
        this.appointee.redirect = true
        this.createAppointee(this.appointee);
      }
    },
  },
};
</script>

<style></style>
