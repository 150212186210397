<template>
  <Form :heading1="'Create profile'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'National ID / Passport'"
            @input="setNationalId"
            :types="'text'"
            :not="true"
          />
          <TextInput
            :label="'Short description'"
            @input="setDescription"
            :types="'textarea'"
            :not="true"
          />
        </div>
        <div class="flex-1">
          <TextInput
            :label="'Upload Profile photo'"
            @input="setProfile"
            :not="true"
            :types="'file'"
          />
          <DragAndDrop :label="'CV'" @input="setCV" :not="true" />
        </div>
      </div>
      <Button :first-l="'Prev'" :backUrl="'/create-appointment/userdetails?cached=true'" :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import DragAndDrop from "/src/components/form/DragAndDrop.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "CreateThirdStep",
  components: {
    Button,
    TextInput,
    Form,
    DragAndDrop,
  },
  data: () => ({
    appointeeFiles: {
      imageUrl: null,
      cvPath: null,
      clicked: false,
    },
  }),
  computed: {
    ...mapState("appointment", { appointee: "appointee" }),
  },
  methods: {
    setNationalId(value) {
      this.appointee.nationalId = value;
    },
    setProfile(value) {
      // this.appointee.imageUrl = value;
      this.appointeeFiles.imageUrl = value;
    },
    setDescription(value) {
      this.appointee.description = value;
    },
    setCV(value) {
      // this.appointee.cvPath = value;
      this.appointeeFiles.cvPath = value;
    },

    ...mapActions({ createAppointment: "appointment/createAppointment" }),
    doSave() {
      const { imageUrl, cvPath } = this.appointeeFiles;
      if (isNaN(this.appointee.nationalId)) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "National ID / Passport seems to be invalid",
          uptime: 3000,
          hasError: true,
        });
      } else {
        setTimeout(() => {
          this.clicked = false;
        }, 5000);
        if (!this.clicked) {
          this.clicked = true;
          this.createAppointment({
            imageUrl,
            cvPath,
          });
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Creating appointment ...",
            uptime: 3000,
          });
        }
      }
    },
  },
};
</script>

<style></style>
