<template>
  <Form :heading1="'Create a profile'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'Title'"
            @input="setTitle"
            :types="'text'"
            :defaultValue="appointment ? appointment.title : ''"
          />
          <TextInput
            :label="'Description'"
            @input="setDescription"
            :types="'textarea'"
            :defaultValue="appointment ? appointment.description : ''"
          />
        </div>
        <div class="flex-1">
          <TextInput
            :label="'Address'"
            @input="setAddress"
            :types="'text'"
            :defaultValue="appointment ? appointment.address : ''"
          />

          <div class="md:inline-flex md:space-x-14">
            <div class="flex-1">
              <TextInput
                :label="'Date'"
                @input="setDate"
                :types="'date'"
                :defaultValue="
                  appointment
                    ? `${appointment.date.toString().split(' ')[0]}`
                    : ''
                "
              />
            </div>

            <div class="flex-1">
              <TextInput
                :label="'Time'"
                @input="setTime"
                :types="'time'"
                :defaultValue="
                  appointment
                    ? `${appointment.date.toString().split(' ')[1]}`
                    : ''
                "
              />
            </div>
          </div>
        </div>
      </div>

      <Button :first-l="'Prev'" :backUrl="'/appointments'" :last-l="'Next'" @action="doCreate" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import { mapMutations, mapState } from "vuex";
import { formatDate } from "/src/functions";

export default {
  name: "CreateFirstStep",
  components: {
    Button,
    TextInput,
    Form,
  },
  computed: {
    ...mapState("appointment", { appointment: "appointment" }),
  },
  beforeMount() {
    if (!this.$route.query.cached) {
      this.createAppointment({
        title: "",
        description: "",
        address: "",
        date: new Date().toISOString().substr(0, 10),
        time: "",
        clear: true,
      });
    }
  },
  methods: {
    setTitle(value) {
      this.appointment.title = value;
    },
    setDescription(value) {
      this.appointment.description = value;
    },
    setAddress(value) {
      this.appointment.address = value;
    },
    setDate(value) {
      this.appointment.date = formatDate(value);
    },
    setTime(time) {
      console.log("Time set ", time);
      this.appointment.time = time;
    },
    ...mapMutations("appointment", { createAppointment: "setAppointment" }),
    doCreate() {
      const { address, date, description, title, time } = this.appointment;

      if (title == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "appointment title is required",
          uptime: 1000,
          hasError: true,
        });
      } else if (description == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "appointment description is required",
          uptime: 1000,
          hasError: true,
        });
      } else if (address == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "appointment address is required",
          uptime: 1000,
          hasError: true,
        });
      } else if (date == null) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "appointment date is required",
          uptime: 1000,
          hasError: true,
        });
      }
      // else if (new Date(date) < new Date()) {
      //   this.$store.dispatch("notification/SET_NOTIFICATION", {
      //     msg: "invalid date",
      //     uptime: 1000,
      //     hasError: true,
      //   });
      // }
      else if (time == null) {
        console.log(time, typeof time);
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "appointment time is required",
          uptime: 1000,
          hasError: true,
        });
      } else {
        let newDate = date.toString().split(" ")[0] + " " + time + ":00";
        // this.$store.dispatch("notification/SET_NOTIFICATION", {
        //   msg: "Creating appointment ...",
        //   uptime: 1000,
        // });
        this.createAppointment({
          address,
          date: newDate,
          description,
          title,
          time,
          clear: this.$route.query.cached,
          redirect: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
$namespace: "xmx";
$default-color: #555;
$primary-color: #1284e7;
</style>
